<template>
  <div>
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <CModalExtended
      size="lg"
      :title="titleModal"
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      class="modal-extended"
    >
      <CRow class="justify-content-center">
       <CCol sm="12" lg="12" >
          <div class="form-group form-row" rol="group">
            <label v-if="FgOperator" class="required col-form-label-sm col-sm-10 col-lg-4 text-right" for="pais" >
              {{$t('label.staffOperator')}}
            </label>
            <label v-if="!FgOperator" class="required col-form-label-sm col-sm-10 col-lg-4 text-right" for="pais">
              {{$t('label.other')+' '+$t('label.operator')}}
            </label>
            <div class="col-sm-2 col-lg-1">
               <CSwitch
                  color="watch"
                  variant="3d"
                  type="checkbox"
                  :checked.sync="FgOperator"
                />
            </div>
            <div class="col-sm-10 col-lg-7" v-if="FgOperator">
               <v-select 
                style="min-height: 30px !important; font-size: 12px;"
                :placeholder="$t('label.select')"
                :options="operatorOptions"
                label="label"
                :is-valid="hasError($v.report.operatorName)"
                :invalid-feedback="errorMessage($v.report.operatorName)"
                v-model="operatorNameStaff"
                class="select-operator--name"
                :class="computedSelectOperator ? 'select-operator--correct' : (verifyOperatorName ? 'select-operator--error' : '') "
              />
            </div> 
            <div class="col-sm-12 col-lg-5" v-if="FgOperator && (operatorNameStaff == null || operatorNameStaff == '') && verifyOperatorName"></div>
            <div class="col-sm-10 col-lg-7  text-invalid-feedback text-left" v-if="FgOperator && (operatorNameStaff == null || operatorNameStaff == '') && verifyOperatorName">
              {{$t('label.required')}}
            </div>
            <div class="col-sm-10 col-lg-7" v-if="!FgOperator">
               <CInput
                :placeholder="$t('label.name')"                                         
                v-uppercase
                v-model="operatorNameOther"
                :value="operatorNameOther"
                class="mb-0"
                :is-valid="hasError($v.report.operatorName)" 
                :invalid-feedback="errorMessage($v.report.operatorName)"
                size="sm"
              />
            </div>
          </div> 
        </CCol>
        <CCol sm="12" lg="12" >
          <div class="form-group form-row" rol="group">
            <label v-if="FgSupervisor" class="required col-form-label-sm col-sm-10 col-lg-4 text-right" for="pais">
              {{$t('label.staffSupervisor')}}
            </label>
            <label v-if="!FgSupervisor" class="required col-form-label-sm col-sm-10 col-lg-4 text-right" for="pais">
              {{$t('label.other')+' '+$t('label.supervisor')}}
            </label>
            <div class="col-sm-2 col-lg-1">
               <CSwitch
                  color="watch"
                  variant="3d"
                  type="checkbox"
                  :checked.sync="FgSupervisor"
                  
                />
            </div>
            <div class="col-sm-10 col-lg-7" v-if="FgSupervisor">
              <v-select 
                style="min-height: 30px !important; font-size: 12px;"
                :placeholder="$t('label.select')"
                :options="supervisorOptions"
                label="label"
                :is-valid="hasError($v.report.supervisorName)"
                :invalid-feedback="errorMessage($v.report.supervisorName)"
                v-model="supervisorNameStaff"
                class="select-supervisor--name"
                :class="computedSelectSupervisor ? 'select-supervisor--correct' : (verifySupervisorName ? 'select-supervisor--error' : '') "
              />
            </div> 
            <div class="col-sm-12 col-lg-5" v-if="FgSupervisor && (supervisorNameStaff == '' || supervisorNameStaff == null) && verifySupervisorName"></div>
            <div class="col-sm-10 col-lg-7  text-invalid-feedback text-left" v-if="FgSupervisor && (supervisorNameStaff == '' || supervisorNameStaff == null) && verifySupervisorName">
              {{$t('label.required')}}
            </div>
            <div class="col-sm-10 col-lg-7" v-if="!FgSupervisor">
               <CInput
                :placeholder="$t('label.name')"                                         
                v-uppercase
                v-model="supervisorNameOther"
                :value="supervisorNameOther"
                class="mb-0"
                :is-valid="hasError($v.report.supervisorName)" 
                :invalid-feedback="errorMessage($v.report.supervisorName)"
                size="sm"
              />
            </div>
          </div> 
        </CCol>
        <CCol sm="12" lg="12" class="mb-1">
          <CInput
            :placeholder="$t('label.received')"                                         
            :horizontal="{label:'col-sm-12 col-lg-4 text-right required', input:'col-sm-12 col-lg-8'}"
            v-uppercase
            :label="$t('label.received')"
            v-model.trim="$v.report.received.$model" 
            :value="report.received"
            :is-valid="hasError($v.report.received)" 
            :invalid-feedback="errorMessage($v.report.received)"
            size="sm"
          />
        </CCol>

        <CCol sm="11" lg="12">
          <div class="form-group form-row mb-0" rol="group">
            <label class="col-form-label-sm required text-right col-sm-11 col-lg-4" for="hordadesde">
              {{$t('label.date')}}
            </label>
            <div class="col-sm-11 col-lg-4 input-group">
              <vue-datepicker
                valueType="format"
                format="DD/MM/YYYY"
                :open="openDate"
                :lang="this.$i18n.locale"
                @update:open="functionDate($event)"
                @clickoutside="openDate = false"
                @select="openDate = false"
                @pick="openDate = false"
                @close="openDate = false"
                :clearable="false"
                :disabled-date="validateDateRange"
                v-model.trim="$v.report.date.$model"
                :append-to-body="false"
                class="w-100"
              >
                <template #input>
                  <CInput
                    addInputClasses="pr-0"
                    class="w-100 float-left"
                    v-uppercase
                    v-model="report.date"
                    placeholder="DD/MM/YYYY"
                    :invalid-feedback="$t('label.required')"
                    :is-valid="hasError($v.report.date)"
                    v-model.trim="$v.report.date.$model"
                    @keypress="rightDate($event)"
                    size="sm"
                  >
                    <template #append-content>
                      <CIcon name="cil-calendar" />
                    </template>
                  </CInput>
                </template>
                <template #icon-calendar>
                  <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </div>
            <label class="col-form-label-sm required text-right col-sm-11 col-lg-1 pl-0" for="hordadesde">
              {{$t('label.hour')}}
            </label>
            <div class="col-sm-11 col-lg-3 pl-0 input-group">
              <vue-datepicker
                valueType="format"
                type="time"
                :format="'HH:mm'"
                v-model="report.hour"
                :clearable="false"
                v-model.trim="$v.report.hour.$model"
                :append-to-body="false"
                class="w-100"
              >
                <template #input>
                  <CInput
                    class="w-100 float-left"
                    v-uppercase
                    v-model="report.hour"
                    placeholder="HH:MM"
                    :invalid-feedback="$t('label.required')"
                    :is-valid="hasError($v.report.hour)"
                    v-model.trim="$v.report.hour.$model"
                    @keypress="formatHourMMHH($event)"
                    size="sm"
                  >
                    <template #append-content>
                      <CIcon name="cil-clock" />
                    </template>
                  </CInput>
                </template>
                <template #icon-calendar>
                  <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </div>
          </div>
        </CCol>
      </CRow>

      <template #footer>
        <CButton outline color="add" @click="getPdf()" :disabled="isSubmit">
          <CIcon name="checkAlt" />&nbsp; {{$t('label.generateReport')}}
        </CButton>
        <CButton color="wipe" @click="closeModal" :disabled="isSubmit">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import General from '@/_mixins/general';
import ReportesVisitas from '@/_mixins/reportesVisitas';
import { DateFormater, alertPropertiesHelpers } from '@/_helpers/funciones';
import inspectionValidation from '@/_validations/inspection/inspectionValidation';
import { mapState } from 'vuex';

function data() {
  return {
    showModal: false,
    titleModal: this.$t('label.generateReport')+" "+this.$t('label.damage'),
    loadingOverlay: false,

    //report
    report: {
      date: 'DD/MM/YYYY',
      hour: 'HH:MM',
      operatorName: '',
      supervisorName: '',
      received: '',
      Status: 1,
    },
    openDate: false,
    previousDate: new Date(),
    laterDate: new Date(),

    FgOperator: true,
    FgSupervisor: true,

    FgInspectionType: true, // 0 seals 1 damage
    ContainerCode: '',
    DischargePort: '',
    Estatus: '',
    LoadPort: '',
    TpCargoId: '',
    TpCargoName: '',
    TpInspectionName: '',
    VisitCargoId: '',
    VisitCargoInspectionId: '',
    inspectionType: '',
    TpCargoCode: '',
    Size: '',
    TpInspectionId: '',
    operatorList: [],
    supervisorList: [],

    supervisorNameStaff: '',
    operatorNameStaff: '',
    verifyOperatorName: false,
    verifySupervisorName: false,
    operatorNameOther: '',
    supervisorNameOther: '',

    DamageInspection: [],
    SealInspection: [],
    isSubmit: false,
  };
}

function closeModal() {
  this.refreshComponentReport();
  this.$emit('closeModal');
  this.$emit('child-refresh', true);
  this.showModal=false;
  this.openDate = false;
}

//datepicker
function functionDate(event) {
  if (event) {
    this.openDate = event;
  }
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}


function getDateRange(){
    let currentDate = new Date();

    if(this.itinerarySelected.Ata!=undefined){ 
        this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
    }

    if(this.itinerarySelected.Ata==undefined){
        this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
    }

    if(this.itinerarySelected.Atd!=undefined){  
        this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
    }

    if(this.itinerarySelected.Atd==undefined){
        this.laterDate = currentDate;
    }
  }

async function getPdf() {
  try{
    this.loadingOverlay = true;
    this.isSubmit = true;
    this.$v.report.$touch();

    if (this.$v.report.$error) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      this.verifyOperatorName = true;
      this.verifySupervisorName = true;
      throw this.$t('label.errorsPleaseCheck');
    }

    let dateNow = new Date();
    let currentTime = DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatTimeZoneToDateTime(dateNow));
    let currentDate = DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatTimeZoneToDateTime(dateNow));
    let startTime = (this.itinerarySelected.Ata==undefined) ?
      DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta)) :
      DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata));
    let startDate = (this.itinerarySelected.Ata==undefined) ?
      DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta)) :
      DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata));

    if((this.report.date === currentDate) && (this.report.hour > currentTime)){
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsCurrentDateTimePleaseCheck');
    }

    if((this.report.date === startDate) && (this.report.hour < startTime)){
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsStartDateTimePleaseCheck');
    }

    const VisitCargoInsMasterJson = {
      CompanyBranchId: this.branch.CompanyBranchId,
      VisitCargoInspectionId: this.VisitCargoInspectionId,
      TpInspectionId: this.TpInspectionId,
      VisitCargoId: this.VisitCargoId ? this.VisitCargoId : '',    
      InspectionTransactionDate: DateFormater.formatDateTimeWithoutSlash(this.report.date+' '+this.report.hour),     
      OperatorName: this.FgOperator ? this.report.operatorName.label : this.report.operatorName,  
      SupervisorName: this.FgSupervisor ? this.report.supervisorName.label : this.report.supervisorName,
      ReceivedName: this.report.received ? this.report.received : '',        
    };
    
    let method = 'POST';
    let ruta = 'VisitCargoInspectionMaster-insert';
    let listado = Array;

    this.$http
      .ejecutar (method, ruta, VisitCargoInsMasterJson, { root: 'VisitCargoInsMasterJson' })
      .then((response) => {
        if (response.status === (200 || 201)) {
          listado = [...response.data.data];               
          this.getReportInspection(listado[0], response.data.data[0].InpectionNumberCode, this.report.date+' '+this.report.hour);
          /*this.$notify({
            group: 'container',
            title: '¡'+this.$t('label.success')+'!',
            text: response.data.data[0].Response,
            type: "success"
          });*/
        }
      })
      .catch((e) => {
        this.loadingOverlay = false;
        this.isSubmit = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: "error"
        });
      })
      ; 
  }catch (error) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: error,
      type: "error"
    });
  }
}

async function getReportInspection(item, correlative, transactionDate) {
  this.loadingOverlay = true;
  this.isSubmit = true;
  let dataReport = Array;
  let damageReportData = [];
  let inspectionData = [];
  let _this = this.$i18n.locale;  
  let estatus, InspectionMoment, seals, damage, inspectionType;

  await this.$http.get("VisitCargoDamageReport", { VisitCargoInspectionMasterId: item.VisitCargoInspectionMasterId })
  .then(response => {
    dataReport = [...response.data.data];
    if(dataReport.length > 0 ) {
      let i = 1;

      seals = dataReport[0].SealInspectionJson;
      damage = dataReport[0].DamageDescriptionJson;

      if (dataReport[0].DamageDescriptionJson.length > 0){
        let j = dataReport[0].DamageDescriptionJson.length;
        InspectionMoment = damage[j-1].InspectionMomentId ? damage[j-1].InspectionMomentId : '';
        inspectionType = true;

        inspectionData = damage.map(damage => Object.assign({}, inspectionData, {
          TpDamage: damage.TpDamageAbbrev+":"+(_this=='en' ? 
            (damage.TpDamageNameEn ? damage.TpDamageNameEn : '') : 
            (damage.TpDamageName ? damage.TpDamageName : '')
          )     ,
          TpCargoViewName: _this=='en' ? 
            (damage.TpCargoViewNameEn ? damage.TpCargoViewNameEn : '') : 
            (damage.TpCargoViewName ? damage.TpCargoViewName : ''),
          Dimension: ((damage.Width!=="") && (damage.Height!=="")) ? damage.Width+" x "+damage.Height : "",
          Observation: damage.Observation ? damage.Observation: '',
        }));
      
      } else if(dataReport[0].SealInspectionJson.length > 0){
        InspectionMoment = dataReport[0].SealInspectionJson[0].InspectionMomentId ? dataReport[0].SealInspectionJson[0].InspectionMomentId : '';
        inspectionType = false;
        inspectionData = [
          {
            PreviousSeal: seals[0].Seal1[0].PreviousSeal ? 'S1: '+seals[0].Seal1[0].PreviousSeal : 'S1: ',
            Seal: seals[0].Seal1[0].Seal ? 'S1: '+seals[0].Seal1[0].Seal : 'S1:',
            SealConditionName: _this=='en' ?
              (seals[0].Seal1[0].SealConditionNameEn ? 'S1: '+seals[0].Seal1[0].SealConditionNameEn : '') : 
              (seals[0].Seal1[0].SealConditionNameEs ? 'S1: '+seals[0].Seal1[0].SealConditionNameEs : ''),
            ObservationSeal: seals[0].Seal1[0].ObservationSeal ? 'S1: '+seals[0].Seal1[0].ObservationSeal : 'S1: ',
          },
          {
            PreviousSeal: seals[0].Seal2[0].PreviousSeal ? 'S2: '+seals[0].Seal2[0].PreviousSeal : 'S2: ',
            Seal: seals[0].Seal2[0].Seal ? 'S2: '+seals[0].Seal2[0].Seal : 'S2: ',
            SealConditionName: _this=='en' ? 
              (seals[0].Seal2[0].SealConditionNameEn ? 'S2: '+seals[0].Seal2[0].SealConditionNameEn : '') : 
              (seals[0].Seal2[0].SealConditionNameEs ? 'S2: '+seals[0].Seal2[0].SealConditionNameEs : ''),
            ObservationSeal: seals[0].Seal2[0].ObservationSeal ? 'S2: '+seals[0].Seal2[0].ObservationSeal : 'S2: ',
          },
          {
            PreviousSeal: seals[0].Seal3[0].PreviousSeal ? 'S3: '+seals[0].Seal3[0].PreviousSeal : 'S3: ',
            Seal: seals[0].Seal3[0].Seal ? 'S3: '+seals[0].Seal3[0].Seal : 'S3: ',
            SealConditionName: _this=='en' ? 
              (seals[0].Seal3[0].SealConditionNameEn ? 'S3: '+seals[0].Seal3[0].SealConditionNameEn : '') :
              (seals[0].Seal3[0].SealConditionNameEs ? 'S3: '+seals[0].Seal3[0].SealConditionNameEs : ''),
            ObservationSeal: seals[0].Seal3[0].ObservationSeal ? 'S3: '+seals[0].Seal3[0].ObservationSeal : 'S3: ',
          },
          {
            PreviousSeal: seals[0].Seal4[0].PreviousSeal ? 'S4: '+seals[0].Seal4[0].PreviousSeal : 'S4: ',
            Seal: seals[0].Seal4[0].Seal ? 'S4: '+seals[0].Seal4[0].Seal : 'S4: ',
            SealConditionName: _this=='en' ? 
              (seals[0].Seal4[0].SealConditionNameEn ? 'S4: '+seals[0].Seal4[0].SealConditionNameEn : '') :
              (seals[0].Seal4[0].SealConditionNameEs ? 'S4: '+seals[0].Seal4[0].SealConditionNameEs : ''),
            ObservationSeal: seals[0].Seal4[0].ObservationSeal ? 'S4: '+seals[0].Seal4[0].ObservationSeal : 'S4: ',
          },
        ];   
      }   
      estatus = this.Estatus ? this.Estatus : '',
      damageReportData = dataReport.map(dataReport => Object.assign({}, damageReportData, {
        Nro: i++,
        BaySlot: dataReport.BaySlot ? dataReport.BaySlot : '', 
        BerthName: dataReport.BerthName ? dataReport.BerthName : '', 
        BlNo: dataReport.BlNo ? (dataReport.BlNo=null ? '' : dataReport.BlNo) : '',
        ContainerCode: dataReport.ContainerCode ? dataReport.ContainerCode : '', 
        DateHourOper: dataReport.DateHourOper ? dataReport.DateHourOper : '', 
        DischargePort: dataReport.DischargePort ? dataReport.DischargePort : '', 
        LoadPort: dataReport.LoadPort ? dataReport.LoadPort : '', 
        Size: dataReport.Size ? dataReport.Size : '', 
        TpCargoCode: dataReport.TpCargoCode ? dataReport.TpCargoCode : '', 
        TpCargoId: dataReport.TpCargoId ? dataReport.TpCargoId : '', 
        VesselNameReport: dataReport.VesselName ? dataReport.VesselName : '', 
        VisitCargoId: dataReport.VisitCargoId ? dataReport.VisitCargoId : '', 
        Voyage: dataReport.Voyage ? dataReport.Voyage : '', 
        OperatorName:  dataReport.OperatorName ? dataReport.OperatorName : '', 
        ReceivedName: dataReport.ReceivedName ? dataReport.ReceivedName : '',           
        SupervisorName: dataReport.SupervisorName ? dataReport.SupervisorName : '',
        Estatus: estatus ? estatus : '',
        InspectionMoment: InspectionMoment,
        MovStowage: dataReport.MovStowageId ? dataReport.MovStowageId : '',
        TransacStowage: dataReport.TransacStowageId ? dataReport.TransacStowageId : '',
        FgActInspectionMaster: dataReport.FgActInspectionMaster ? true : false,
      }));
    }
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })

  if(dataReport.length > 0 ) {
    await this.getDamageReport(damageReportData[0], inspectionType, correlative, transactionDate, inspectionData);  
    this.closeModal();
    this.loadingOverlay = false;
    this.isSubmit = false;
  }else{  
    this.loadingOverlay = false;
    this.isSubmit = false;
    this.$notify({  
      group: 'container',
      title: '¡Error!',
      text: this.$t('label.noRecordsAvailbleReport'),
      type: "error" 
    });
  }
}


async function getRoleDetailList() {
  let listado = Array;
  await this.$http.get("VisitRoleInspectionAll-list", { VisitId: this.Visit })
  .then(response => {
    listado = [...response.data.data];

    let arreglo1 = [];
    let arreglo2 = [];
    listado.forEach(function (item) {
      if(!item.FgSupervisor){
        arreglo1.push({
          Nro: item.Nro,
          StaffData: item.StaffData,
        });
      }
      if(item.FgSupervisor){
        arreglo2.push({
          Nro: item.Nro,
          StaffData: item.StaffData,
        });
      }    
    });
    this.operatorList = arreglo1; 
    this.supervisorList = arreglo2;       
    
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
   // this.loadingTable = false;
  });
}

function refreshComponentReport(){
  this.report.date= '';
  this.report.hour= '';
  this.report.operatorName= '';
  this.report.supervisorName= '';
  this.report.received= '';
  this.report.Status= 1;
  this.openDate= false;
  this.previousDate= new Date();
  this.laterDate= new Date();

  this.FgOperator= true;
  this.FgSupervisor= true;

  this.FgInspectionType= true; // 0 seals 1 damage
  this.ContainerCode= '';
  this.DischargePort= '';
  this.Estatus= '';
  this.LoadPort= '';
  this.TpCargoId= '';
  this.TpCargoName= '';
  this.TpInspectionName= '';
  this.VisitCargoId= '';
  this.VisitCargoInspectionId= '';
  this.inspectionType= '';
  this.TpCargoCode= '';
  this.Size= '';
  this.TpInspectionId= '';
  this.operatorList= [];
  this.supervisorList= [];

  this.supervisorNameStaff= '';
  this.operatorNameStaff= '';
  this.verifyOperatorName= false;
  this.verifySupervisorName= false;
  this.operatorNameOther= '';
  this.supervisorNameOther= '';

  this.DamageInspection= [];
  this.SealInspection= [];
  this.isSubmit = false;
  this.loadingOverlay = false;
  this.$v.$reset();
}

//computed
  function operatorOptions(){
    return this.operatorList.map((item) => Object.assign({}, item, {
      value: item.Nro,
      label: item.StaffData,
    }));
  }
  function supervisorOptions(){
    return this.supervisorList.map((item) => Object.assign({}, item, {
      value: item.Nro,
      label: item.StaffData,
    }));
  }

function computedSelectOperator() {
  if (this.operatorNameStaff === '' || this.operatorNameStaff === null) {
    return false;
  } else {
    return true;
  }
}

function computedSelectSupervisor() {
  if (this.supervisorNameStaff === '' || this.supervisorNameStaff === null) {
    return false;
  } else {
    return true;
  }
}
export default {
  name: 'modal-generate-report-inspection',
  data,
  props: {
    modal: null,
  },
  mixins: [General, ReportesVisitas],
  directives: UpperCase,
  validations:inspectionValidation,
  components: {
    
  },
  watch: {
    modal: async function () {
      if (this.modal) {

        this.refreshComponentReport();
        this.getDateRange();
        this.titleModal = this.$t('label.generateReport')+" "+this.$t('label.damage')+': '+this.modal.ContainerCode;
        this.report.hour = this.modal.TransaRegDate ? DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatDateTimeWithSlash(this.modal.TransaRegDate)) : '';
        this.report.date = this.modal.TransaRegDate ? DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(this.modal.TransaRegDate)) : '';

        this.showModal = true; 
        this.FgOperator = true; 
        this.FgInspectionType = this.modal.FgInspectionType ? true: false; // 0 seals 1 damage
        this.ContainerCode = this.modal.ContainerCode;
        this.DischargePort = this.modal.DischargePort;
        this.Estatus = this.modal.Estatus;
        this.LoadPort = this.modal.LoadPort;
        this.TpCargoId = this.modal.TpCargoId;
        this.TpCargoName = this.modal.TpCargoName;
        this.TpInspectionName = this.modal.TpInspectionName;
        this.VisitCargoId = this.modal.VisitCargoId;
        if (this.modal.TpInspectionId === 'E27F9D05-9879-43C6-A186-87D707984033')
          this.VisitCargoInspectionId =  this.modal.VisitCargoInspectionId;
        else
          this.VisitCargoInspectionId =  '';
        this.inspectionType = this.modal.inspectionType;
        this.TpCargoCode = this.modal.TpCargoCode;
        this.Size = this.modal.Size;

        this.BaySlot = this.modal.BaySlot;
        this.BlNo = this.modal.BlNo;

        this.TpInspectionId = this.modal.TpInspectionId;
        this.DamageInspection = this.modal.DamageInspectionJson;
        this.SealInspection = this.modal.SealInspection;

        this.getRoleDetailList();
        this.$emit('closeModal');
      
        this.verifyOperatorName = false;
        this.verifySupervisorName = false;
      }
    },
    FgOperator: function () {
      if (this.FgOperator) {
        this.operatorNameOther = '';
      }else{
        this.operatorNameStaff = '';
      }
    },
    FgSupervisor: function () {
      if (this.FgSupervisor) {
        this.supervisorNameOther = '';
      }else{
        this.supervisorNameStaff = '';
      }
    },
    operatorNameStaff: function () {
      if (this.FgOperator) {
        this.report.operatorName = this.operatorNameStaff;
      }else{
        this.report.operatorName = this.operatorNameOther;
      }
    },
    supervisorNameStaff: function () {
      if (this.FgSupervisor) {
        this.report.supervisorName = this.supervisorNameStaff;
      }else{
        this.report.supervisorName = this.supervisorNameOther;
      }
    },
    operatorNameOther: function () {
      if (this.FgOperator) {
        this.report.operatorName = this.operatorNameStaff;
      }else{
        this.report.operatorName = this.operatorNameOther;
      }
    },
    supervisorNameOther: function () {
      if (this.FgSupervisor) {
        this.report.supervisorName = this.supervisorNameStaff;
      }else{
        this.report.supervisorName = this.supervisorNameOther;
      }
    },
  },
  methods: {
    closeModal,
    functionDate,
    validateDateRange,
    getDateRange,
    getPdf,
    getRoleDetailList,
    refreshComponentReport,
    getReportInspection,
  },
  computed: {
    operatorOptions,
    supervisorOptions,
    computedSelectOperator,
    computedSelectSupervisor,
    ...mapState({
        Visit: state => state.visitas.VisitId,
        ItineraryId: state => state.visitas.ItineraryId,
        itinerarySelected: state => state.visitas.itinerarySelected,  
        user: (state) => state.auth.user,  
        branch: state => state.auth.branch,
    }),
  },
};
</script>
<style lang="scss" scope>
  .w-80 {
    width: 80% !important;
  }

.select-operator--name .vs__dropdown-menu {
  max-height: 280px !important;
}
.select-operator--correct {
  .vs__dropdown-toggle {
    border-color: #2eb85c !important;
  }
}
.select-operator--error {
  .vs__dropdown-toggle {
    border-color: #e55353 !important;
  }
}
.select-supervisor--name .vs__dropdown-menu {
  max-height: 280px !important;
}
.select-supervisor--correct {
  .vs__dropdown-toggle {
    border-color: #2eb85c !important;
  }
}
.select-supervisor--error {
  .vs__dropdown-toggle {
    border-color: #e55353 !important;
  }
}
</style>
